import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';

import { Contato, Direitos } from './style';

// api
import ademail from '../../services/ademail';

export default function Footer() {
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
      mensagem: Yup.string().required('*Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                        <p>Nome: ${values.nome}</p>
                        <p>E-mail: ${values.email}</p>
                        <p>Mensagem: ${values.mensagem}</p>
                      `;

      const mail = {
        to: 'sementes@frisia.coop.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Novo contato via site - Sementes Batavo',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <Contato id="contato">
        <div className="formulario">
          <div className="infos">
            <a href="/" className="logo">
              <img
                src={require('../../assets/logo-branca.svg')}
                alt="Sementes Batavo"
              />
            </a>
            <div className="line" />
            <div className="endereco">
              <p>Comercial Matriz – Carambeí (PR)</p>
              <a href="tel: +554232319325">42 3231-9325</a>
              <a
                href="https://www.google.com/maps/place/Sementes+Batavo/@-24.942671,-50.131763,15z/data=!4m2!3m1!1s0x0:0x37abf7eab0e9b6af?sa=X&ved=2ahUKEwiOwJOii4zsAhVLGLkGHXMhAH0Q_BIwHnoECBMQBg"
                target="_blank"
                rel="noopener noreferrer"
              >
                Av. dos Pioneiros, 2324 - Centro <br /> Caixa postal: 84145-000{' '}
                <br />
                Carambeí - PR
              </a>
            </div>
            <div className="line" />
            <div className="social">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/sementesbatavo/"
              >
                <FaFacebookF />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/sementesbatavo/"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </Contato>
    </>
  );
}
