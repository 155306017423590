import React, { useState } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { FixedNavbar } from './style';

export default function FixedHeader() {
  const [openMenu2, setOpenMenu2] = useState(false);

  return (
    <FixedNavbar isOpen={openMenu2}>
      <a href="/" className="logo">
        <img src={require('../../assets/logo.svg')} alt="Sementes Batavo" />
      </a>
      <button
        className={`hamburger hamburger--collapse ${
          openMenu2 ? 'is-active' : ''
        }`}
        type="button"
        onClick={() => setOpenMenu2(!openMenu2)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <nav>
        <ul>
          <li>
            <a href="https://wp.sementesbatavo.com.br/inicio">INÍCIO</a>
          </li>
          <li>
            <a href="https://wp.sementesbatavo.com.br/historia">HISTÓRIA</a>
          </li>
          <li>
            <a href="https://wp.sementesbatavo.com.br/qualidade">QUALIDADE</a>
          </li>
          <li>
            <a href="https://wp.sementesbatavo.com.br/tsi">TSI</a>
          </li>
          <li>
            <a href="https://wp.sementesbatavo.com.br/cultivares">CULTIVARES</a>
          </li>
          <li>
            <a href="https://wp.sementesbatavo.com.br/parceiros">PARCEIROS</a>
          </li>
          <li>
            <a onClick={() => setOpenMenu2(false)} href="#contato">
              CONTATO
            </a>
          </li>
          {/* <li>
            <a href="/area-restrita">ACESSO DO CLIENTE</a>
          </li> */}
          <li className="social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/sementesbatavo/"
              onClick={() => setOpenMenu2(false)}
            >
              <FaFacebookF />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/sementesbatavo/"
              onClick={() => setOpenMenu2(false)}
            >
              <FaInstagram />
            </a>
          </li>
        </ul>
      </nav>
    </FixedNavbar>
  );
}
