import React, { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import apiPainel from '../../services/api-painel';
import { ReactComponent as ArrowDownSvg } from '../../assets/img/gray-icon-arrow-down.svg';

import { FixedNavbar, StyledIconContainer } from './style';

export default function FixedHeader() {
  const [openMenu2, setOpenMenu2] = useState(false);
  const [haveSoja, setHaveSoja] = useState(false);
  const [haveTrigo, setHaveTrigo] = useState(false);
  const [haveCevada, setHaveCevada] = useState(false);

  const checkCultivarsOptions = () => {
    apiPainel.get(`/cultivares?filter[tipos]=soja`).then((response) => {
      if (response.data[0]) {
        setHaveSoja(true);
      }
    });
    apiPainel.get(`/cultivares?filter[tipos]=trigo`).then((response) => {
      if (response.data[0]) {
        setHaveTrigo(true);
      }
    });
    apiPainel.get(`/cultivares?filter[tipos]=cevada`).then((response) => {
      if (response.data[0]) {
        setHaveCevada(true);
      }
    });
  };

  useEffect(() => {
    checkCultivarsOptions();
  }, []);

  return (
    <FixedNavbar isOpen={openMenu2}>
      <div className="container">
        <a href="/" className="logo">
          <img src={require('../../assets/logo.svg')} alt="Sementes Batavo" />
        </a>
        <button
          className={`hamburger hamburger--collapse ${
            openMenu2 ? 'is-active' : ''
          }`}
          type="button"
          onClick={() => setOpenMenu2(!openMenu2)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <nav>
          <ul>
            <li>
              <a href="https://wp.sementesbatavo.com.br/historia">História</a>
            </li>
            <li>
              <a href="https://wp.sementesbatavo.com.br/qualidade">Qualidade</a>
            </li>
            <li>
              <a href="https://wp.sementesbatavo.com.br/tsi">TSI</a>
            </li>
            <li className="active-drop">
              <a>
                Cultivares
                <StyledIconContainer>
                  <ArrowDownSvg />
                </StyledIconContainer>
              </a>
              <ul className="dropdown">
                {haveSoja && (
                  <li>
                    <a href="/cultivares/soja">Soja</a>
                  </li>
                )}
                {haveTrigo && (
                  <li>
                    <a href="/cultivares/trigo">Trigo</a>
                  </li>
                )}
                {haveCevada && (
                  <li>
                    <a href="/cultivares/cevada">Cevada</a>
                  </li>
                )}
              </ul>
            </li>
            <li>
              <a href="https://tourvirtual360.com.br/frisia/">Tour 360</a>
            </li>
            <li>
              <a href="https://wp.sementesbatavo.com.br/parceiros">Parceiros</a>
            </li>

            <li>
              <a href="/area-restrita">Área Restrita</a>
            </li>
            <li className="social">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/sementesbatavo/"
                onClick={() => setOpenMenu2(false)}
              >
                <FaFacebook />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/sementesbatavo/"
                onClick={() => setOpenMenu2(false)}
              >
                <FaInstagram />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UC4-d0ixGI9IMF-Ja15-OJNQ"
                onClick={() => setOpenMenu2(false)}
              >
                <FaYoutube />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </FixedNavbar>
  );
}
