import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Historia from '../pages/Historia';
import Qualidade from '../pages/Qualidade';
import Tsi from '../pages/Tsi';
import Cultivares from '../pages/Cultivares';
import Parceiros from '../pages/Parceiros';
import AreaRestrita from '../pages/AreaRestrita';
import ARSolicitarConta from '../pages/AreaRestrita/SolicitarConta';
import ARHome from '../pages/AreaRestrita/Home';
import ARContrato from '../pages/AreaRestrita/Contrato';
import ARNotasFiscais from '../pages/AreaRestrita/NotasFiscais';
import ARCadastro from '../pages/AreaRestrita/Cadastro';
import AproveContract from '../pages/AreaRestrita/AprovacaoContrato';
import ARSacc from '../pages/AreaRestrita/Sacc';
import ARRecuperarSenha from '../pages/AreaRestrita/RecuperarSenha';
import AREsqueciSenha from '../pages/AreaRestrita/EsqueciSenha';
// import NoitedeCampo from '../pages/Noite de Campo';
// import HomeNC from '../pages/Noite de Campo/Home';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/historia" exact component={Historia} />
        <Route path="/qualidade" exact component={Qualidade} />
        <Route path="/tsi" exact component={Tsi} />
        <Route path="/cultivares/:slug" exact component={Cultivares} />
        <Route path="/cultivares" exact component={Cultivares} />
        <Route path="/parceiros" exact component={Parceiros} />
        <Route path="/area-restrita" exact component={AreaRestrita} />
        <Route
          path="/area-restrita/solicitar-conta"
          exact
          component={ARSolicitarConta}
        />
        <Route path="/area-restrita/home" exact component={ARHome} />
        <Route path="/area-restrita/contrato" exact component={ARContrato} />
        <Route
          path="/area-restrita/aprovacao-contrato"
          exact
          component={AproveContract}
        />
        <Route
          path="/area-restrita/notas-fiscais"
          exact
          component={ARNotasFiscais}
        />
        <Route path="/area-restrita/cadastro" exact component={ARCadastro} />
        <Route path="/area-restrita/sacc" exact component={ARSacc} />
        <Route
          path="/area-restrita/esqueci-a-senha"
          exact
          component={AREsqueciSenha}
        />
        <Route
          path="/area-restrita/recuperar-senha"
          exact
          component={ARRecuperarSenha}
        />
        {/* <Route path="/noite-de-campo" exact component={HomeNC} /> */}
        {/* <Route path="/noite-de-campo/home" exact component={HomeNC} /> */}
      </Switch>
    </>
  );
}
