import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import {
  Container,
  Banner,
  Cultivares,
  Tsi,
  Historia,
  Depoimentos,
  Representantes,
} from './styles';

import Header from '../../components/Header/Header';
import FixedHeader from '../../components/Header/FixedHeader';
import Footer from '../../components/Footer/Footer';

export default function Home() {
  const depoimentosSlider = useRef();
  const depoimentosSliderSettings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.location.href = 'https://wp.sementesbatavo.com.br/';
  }, [])


  return (
    <>
      <Helmet>
        <title>Sementes Batavo</title>
        <meta
          name="description"
          content="Conheça as nossas cultivares e leve mais qualidade para o seu
          plantio"
        />
        <meta
          name="keywords"
          content="sementes, batavo, frísia, agroindustria, agronegocio, soja, trigo"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot1.png'}
        />
      </Helmet>
      <FixedHeader />
      <Container>
        <Banner>
          <Header />
          <div className="img">
            <img src={require('../../assets/img/banner.jpg')} alt="Banner" />
          </div>
          <div className="text">
            <h1>A semente do Plantio Direto</h1>
            <p>
              Conheça as nossas cultivares e leve mais qualidade para o seu
              plantio
            </p>
            <a className="arrowbtn" href="/cultivares">
              <p>Saiba mais</p>
              <div className="arrow" />
            </a>
          </div>
          {/* <div className="img">
            <img
              src={require('../../assets/img/noitedecampo-banner.jpg')}
              alt="Banner"
            />
          </div>
          <div className="text">
            <h1>Noite de Campo</h1>
            <p>
              Acesse o evento e confira os conteúdos exclusivos sobre as
              melhores cultivares, dos principais detentores de genética de soja
              do país.
            </p>
            <a className="arrowbtn" href="/noite-de-campo">
              <p>Acessar agora</p>
              <div className="arrow" />
            </a>
          </div> */}
        </Banner>
        <Cultivares>
          <div className="text">
            <h2>
              <span>Cultivares</span> <br />
              Sementes Batavo
            </h2>
            <h5>As melhores genéticas</h5>
            <p>
              Sementes Batavo é parceira das principais detentoras de genética
              de soja e trigo do Brasil e tem uma capacidade de produção que
              chega a 800 mil sacas por ano.
            </p>
          </div>
          <div className="imagens">
            <a href="/cultivares/soja">
              <div className="img">
                <img src={require('../../assets/img/soja.jpg')} alt="Soja" />
              </div>
              <div className="texto">
                <p>Soja</p>
              </div>
            </a>
            <a href="/cultivares/trigo">
              <div className="img">
                <img src={require('../../assets/img/trigo.jpg')} alt="Trigo" />
              </div>
              <div className="texto">
                <p>Trigo</p>
              </div>
            </a>
            <div className="detail">
              <img src={require('../../assets/img/cultivares-bg.png')} alt="" />
            </div>
          </div>
          <div className="line" />
        </Cultivares>
        <Tsi>
          <div className="img">
            <img src={require('../../assets/img/tsi.jpeg')} alt="" />
          </div>
          <div className="texto">
            <h2>
              Tratamento de <span>Sementes</span> Industrial
            </h2>
            <p>
              Conheça os benefícios do mais importante Tratamento de Sementes
              Industrial - TSI
            </p>
            <a href="/tsi" className="arrowbtn">
              <p>Saiba mais</p>
              <div className="arrow" />
            </a>
          </div>
          <div className="bg">
            <img src={require('../../assets/img/tsi-bg.jpg')} alt="" />
          </div>
        </Tsi>
        <Historia>
          <div className="texto">
            <h2>
              A marca que nasceu junto com o <span>Plantio Direto</span>
            </h2>
            <h5>Desde 1972</h5>
            <p>
              A experiência no Plantio Direto, que surgiu nos Campos Gerais
              (PR), com o apoio da Frísia Cooperativa Agroindustrial, trouxe
              reconhecimento à Sementes Batavo, a qual foi batizada como “A
              Semente do Plantio Direto”.
            </p>
            <a href="/historia" className="arrowbtn">
              <p>Saiba mais</p>
              <div className="arrow" />
            </a>
          </div>
          <div className="img">
            <img src={require('../../assets/img/historia-bg.jpg')} alt="" />
          </div>
        </Historia>
        <Depoimentos>
          <h2>As melhores sementes</h2>
          <h5>Testemunhal</h5>
          <div className="carousel">
            <button onClick={() => depoimentosSlider.current.prev()}>
              <IoIosArrowBack />
            </button>
            <Carousel ref={depoimentosSlider} {...depoimentosSliderSettings}>
              <div className="slider">
                <div className="avatar">
                  <img src={require('../../assets/img/avatar1.jpg')} alt="" />
                </div>
                <div className="text">
                  <p className="nome">Jeronimo Queiroz</p>
                  <p>
                    Eu acredito que essa evolução da Sementes Batavo traz uma
                    segurança maior aos cooperados, tanto nas lavouras como no
                    fornecimento da nossa excelência de produção, sendo
                    disponibilizada para terceiros. A Sementes Batavo é um
                    grande exemplo de cooperativismo.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="avatar">
                  <img
                    src={require('../../assets/img/Edson-Marcos-Mance.jpg')}
                    alt=""
                  />
                </div>
                <div className="text">
                  <p className="nome">Edson Marcos Mance</p>
                  <p>
                    A semente é o início de tudo. Sendo a semente boa, de
                    qualidade, a produção já tem um início bom. A gente vem de
                    uma tradição de semente boa da Cooperativa, então temos a
                    confiança em utilizar Sementes Batavo.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="avatar">
                  <img src={require('../../assets/img/auke.jpg')} alt="" />
                </div>
                <div className="text">
                  <p className="nome">Auke Dijkstra Neto</p>
                  <p>
                    Sementes Batavo entrega qualidade, vigor e alta performance
                    além de um serviço ágil e eficiente de entrega e
                    comercialização.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="avatar">
                  <img src={require('../../assets/img/nilson.png')} alt="" />
                </div>
                <div className="text">
                  <p className="nome">Nilson Marcos Baier</p>
                  <p>
                    Eu uso Sementes Batavo pela confiança em todo o trabalho que
                    é realizado dentro da Cooperativa, através da análise da
                    semente que vai chegar até o produtor, gerando a qualidade
                    que nós (produtores) precisamos para realizar um bom
                    plantio.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="avatar">
                  <img src={require('../../assets/img/thiago.jpeg')} alt="" />
                </div>
                <div className="text">
                  <p className="nome">Thiago Nadal Mathuchenko</p>
                  <p>
                    Sementes Batavo está há alguns anos na família, transmitindo
                    confiabilidade, desde a colheita até o plantio.
                  </p>
                </div>
              </div>
              <div className="slider">
                <div className="avatar">
                  <img src={require('../../assets/img/jasper.jpg')} alt="" />
                </div>
                <div className="text">
                  <p className="nome">Jasper Kevin Slob</p>
                  <p>
                    Com as Sementes Batavo eu tenho total confiança e
                    procedência das sementes que estou utilizando na lavoura e
                    que terei a pureza e o stand que preciso para obter alta
                    produtividade. Com variedades competitivas no mercado, tenho
                    ampla escolha para uma safra de alta qualidade.
                  </p>
                </div>
              </div>
            </Carousel>
            <button onClick={() => depoimentosSlider.current.next()}>
              <IoIosArrowForward />
            </button>
          </div>
        </Depoimentos>
        <Representantes>
          <div className="text">
            <h2>
              Encontre um
              <span> representante </span>
              próximo a você
            </h2>
            <div className="line" />
            <img src={require('../../assets/logo.svg')} alt="" />
          </div>
          <div className="mapa">
            <img src={require('../../assets/img/mapa.png')} alt="" />
          </div>
          <div className="cards">
            <div className="verde">
              <div>
                <p>São Paulo</p>
                <p>Minas Gerais</p>
                <p>Goiás</p>
              </div>
              <a href="tel: +5542988649277"> 42 9 8864.9277</a>
            </div>
            <div className="azul">
              <div>
                <p className="parana">
                  Paraná
                  <br />
                  <span> Norte, sudeste e oeste</span>
                </p>
                <p>Mato Grosso do Sul</p>
                <p>Paraguai</p>
              </div>
              <a href="tel: +5542988250778"> 42 9 8825.0778</a>
            </div>
          </div>
        </Representantes>
      </Container>
      <Footer />
    </>
  );
}
